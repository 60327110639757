<template>
    <v-dialog v-model="showInvoiceApprovalDialog" persistent max-width="600px">
        <v-card>
            <v-card-title>
                <span class="headline">{{ title }}</span>
            </v-card-title>
            <v-card-text>
                <p>{{$t('EnableAutoInvoice')}}</p>
            </v-card-text>
            <v-card-actions>
                <v-btn text @click="showInvoiceApprovalDialog = false">{{ $t('No') }}</v-btn>
                <v-spacer />
                <v-btn class="mainAccent lighten-3" dark @click="activate">{{ $t('Yes') }}</v-btn>
                <v-btn color="primary" @click="activateAndSendInvoices">{{ $t('YesAndSendInvoices') }}</v-btn>
            </v-card-actions>
        </v-card>
        <Loading :visible="isLoading" />
    </v-dialog>
</template>

<script>

import { createHelpers } from "vuex-map-fields";
import NotificationMixin from '../../mixins/NotificationMixin';
import Loading from "@/components/Loading";
import { mapActions } from "vuex";

const { mapFields } = createHelpers({
    getterType: "seminarModule/getField",
    mutationType: "seminarModule/updateField",
});

export default {
    name: "InvoiceApprovalDialog",
    mixins: [NotificationMixin],
    components: {
        Loading,
    },
    props: {
        value: Boolean,
        title: {
            type: String,
            required: true
        },
        seminarModule: {
            type: Object,
            required: true
        }

    },
    computed: {
        ...mapFields([
            "error",
            "isLoading",
            "created",
            "violations",
            "updated",
            "byId",
        ]),
        showInvoiceApprovalDialog: {
            get() {
                return this.value;
            },
            set(value) {
                if (!value) {
                    this.$emit('close')
                }
            }
        },
    },
    methods: {
        ...mapActions("seminarModule", {
            retrieveModule: "load",
            deleteModuleItem: "del",
            updateModule: "update",
            actionModule: "action",
        }),
        activateAndSendInvoices() {
            this.actionModule({
                item: this.seminarModule,
                action: "generateAndSendParticipantsInvoice",
                params: [{ key: 'activateAutoInvoice', value: true }],
            });
        },
        activate() {
            this.updateModule({
                ["@id"]: this.seminarModule["@id"],
                autoInvoice: true,
            });
            this.showMessage(this.$t("AutoInvoiceActivated"));
        }
    },
}
</script>
