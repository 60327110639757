<template>
  <v-form>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-select
            :error-messages="typeErrors"
            :label="$t('DiscountType')"
            @blur="$v.item.type.$touch()"
            @input="$v.item.type.$touch()"
            required
            :items="typeValues"
            v-model="item.type"
          ></v-select>
        </v-col>
        <v-col cols="12">
          <v-text-field
            :error-messages="amountErrors"
            :label="$t('DiscountAmount')"
            required
            v-model.number="item.amount"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            :error-messages="descriptionErrors"
            :label="$t('discountDescription')"
            @blur="$v.item.description.$touch()"
            @input="$v.item.description.$touch()"
            v-model="item.description"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import has from 'lodash/has';
import {validationMixin} from 'vuelidate';
import {required} from 'vuelidate/lib/validators';

export default {
  name: 'DiscountForm',
  mixins: [validationMixin],
  components: {},
  props: {
    values: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      default: () => {
      }
    },
    initialValues: {
      type: Object,
      default: () => {
      }
    },
  },
  data() {
    return {
      type: null,
      amount: null,
      description: null,
      typeValues: [
        {text: this.$t('discountFixed'), value: 'fixed'},
        {text: this.$t('discountPercentage'), value: 'percentage'},
      ],
    };
  },
  computed: {
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },
    // region validations
    typeErrors() {
      const errors = [];
      if (!this.$v.item.type.$dirty) {
        return errors;
      }
      has(this.violations, 'type') && errors.push(this.violations.type);
      !this.$v.item.type.required && errors.push(this.$t('Field is required'));

      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.item.description.$dirty) {
        return errors;
      }
      has(this.violations, 'description') && errors.push(this.violations.description);
      return errors;
    },
    amountErrors() {
      const errors = [];
      if (!this.$v.item.amount.$dirty) {
        return errors;
      }

      has(this.violations, 'amount') && errors.push(this.violations.amount);

      !this.$v.item.amount.required && errors.push(this.$t('Field is required'));

      return errors;
    },
    violations() {
      return this.errors || {};
    }
    //endregion
  },
  methods: {
    log(data) {
      console.log(data);
    },
  },
  validations: {
    item: {
      type: {
        required,
      },
      amount: {
        required,
      },
      description: {},
    }
  }
}
</script>
