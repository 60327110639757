<template>
  <div>
    <Toolbar
      :handle-edit="seminarDialogControl"
      :handle-delete="del"
      :handle-copy="copy"
      :enable-delete="isDeleteEnabled"
      :enable-copy="hasRole('ROLE_CREATE_SEMINAR')"
      :enable-edit="isEditEnabled"
    >
      <template slot="left">
        <v-toolbar-title v-if="item">{{ `${$options.servicePrefix} ${item['@id']}` }}</v-toolbar-title>
      </template>
    </Toolbar>
    <v-card class="mx-4" :loading="isLoading">
      <v-card flat class="pt-0" v-if="seminar && seminarModule">
        <v-card-title class="text-h5">
          <div>{{ seminar.name }}</div>
          <div v-if="timeSlot">&nbsp;am {{ formatDateTime(timeSlot.start, 'D.M.Y') }}</div>
          <div class="ml-auto">#{{ seminar.number }}</div>
        </v-card-title>
        <v-card-subtitle class="d-flex justify-start align-center mt-1">
          <StatusChip
            :is-positive="item.enabled"
            type-positive="success"
            type-negative="danger"
            icon-positive="mdi-check-circle"
            icon-negative="mdi-close-circle"
            label-positive="Aktiv"
            label-negative="Inaktiv"
          />

          <template v-if="seminarModule.cancelled">
            <StatusChip
              :is-positive="seminarModule.cancelled"
              icon-positive="mdi-close"
              type-positive="warning"
              label-positive="Storniert"
              label-negative="nicht Storniert"
            />
          </template>
          <template v-else>
            <StatusChip
              :is-positive="seminarModule.confirmed"
              :label-positive="$t('Confirmed')"
              :label-negative="$t('NotConfirmed')"
            />
            <StatusChip
              :is-positive="seminarModule.completed"
              type-negative="notice"
              :label-positive="$t('Completed')"
              :label-negative="$t('NotCompleted')"
            />
          </template>

          <StatusChip
            :is-positive="!item.noOnline"
            type-positive="primary"
            type-negative="notice"
            icon-positive="mdi-broadcast"
            icon-negative="mdi-broadcast-off"
            label-positive="Onlineseminar (BBB)"
            label-negative="Kein Onlineseminar (BBB)"
          />

          <StatusChip
            :is-positive="seminarModule.autoInvoice"
            type-positive="primary"
            type-negative="notice"
            icon-positive="mdi-cash-multiple"
            icon-negative="mdi-close-circle"
            label-positive="Auto. Rechnungsversand aktiviert."
            label-negative="Auto. Rechnungsversand deaktiviert."
            :click-function="seminarModule.autoInvoice ? this.deactivateAutoInvoice : null"
          />
        </v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-card class="fill-height" outlined tile>
                <v-card-text>
                  <h3 class="mb-2">{{ $t('Topics') }}:</h3>
                  <v-chip class="mr-2 mb-2" outlined v-for="topic in topics" :key="topic['@id']">
                    {{ topic.name }}
                  </v-chip>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5">
              <v-card class="pa-1 fill-height" outlined tile>
                <v-card-text>
                  <div v-if="timeSlot">
                    <h3>{{ $t('Appointment') }}:</h3>
                    <div>
                      <strong>{{ $t('Start') }}:</strong>
                      {{ formatDateTime(timeSlot.start, 'L LT') }}
                    </div>
                    <div>
                      <strong>{{ $t('End') }}:</strong>
                      {{ formatDateTime(timeSlot.end, 'L LT') }}
                    </div>
                  </div>
                  <div class="mt-4">
                    <h3>{{ $t('Speaker') }}:</h3>
                    <template v-if="speaker">
                      <template v-if="speakerProfile">
                        {{ speakerProfile.salutation }}
                        {{ speakerProfile.title }}
                        {{ speakerProfile.firstName }}
                        {{ speakerProfile.lastName }}
                      </template>
                      <template v-else>
                        {{ speaker.email }}
                      </template>
                    </template>
                    <div v-if="seminarModule.coSpeaker">
                      <h4>{{ $t('CoSpeaker') }}:</h4>
                      <div v-if="coSpeaker">
                        <template v-if="coSpeakerProfile">
                          {{ coSpeakerProfile.salutation }}
                          {{ coSpeakerProfile.title }}
                          {{ coSpeakerProfile.firstName }}
                          {{ coSpeakerProfile.lastName }}
                        </template>
                        <template v-else>
                          {{ coSpeaker.email }}
                        </template>
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="7">
              <v-card class="pa-1 fill-height" outlined tile>
                <v-card-title>{{ $t('ParticipantInformation') }}:</v-card-title>
                <v-card-text>
                  <div class="d-flex flex-row justify-space-between">
                    <div>
                      <strong>{{ $t('fee') }}:</strong> {{ seminarModule.fee }}€
                    </div>
                    <div>
                      <strong>{{ $t('maxParticipants') }}:</strong> {{ seminarModule.maxParticipants }}
                    </div>
                    <div>
                      <strong>{{ $t('teachingUnits') }}:</strong> {{ seminarModule.teachingUnits }}
                    </div>
                  </div>
                  <div class="mt-4 d-flex flex-row justify-center">
                    <ContentHtmlDialog
                      v-if="seminarModule.seminarContent"
                      :title="$t('seminarContent')"
                      :content-html="seminarModule.seminarContent"
                      :open-label="$t('seminarContent')"
                      open-icon="mdi-magnify-plus-cursor"
                    />
                    <ContentHtmlDialog
                      class="ml-4"
                      :title="$t('additionalConfirmationText')"
                      :content-html="
                        seminarModule.additionalConfirmationText ? seminarModule.additionalConfirmationText : ''
                      "
                      :open-label="$t('additionalConfirmationTextShorten')"
                      open-icon="mdi-magnify-plus-cursor"
                    />

                    <template v-if="hasRole('ROLE_UPDATE_SEMINAR_MODULE')">
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn small text outlined style="text-transform: none" class="ml-4" v-bind="attrs" v-on="on">
                            {{ $t('filesToDownload') }}
                            <v-icon right dark>mdi-file-download</v-icon>
                          </v-btn>
                        </template>
                        <v-list dense>
                          <v-list-item
                            v-if="!seminarModule.completed"
                            @click="previewParticipantConfirmation(seminarModule)"
                          >
                            <v-list-item-icon>
                              <v-icon right>{{ 'mdi-file-find' }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>{{ $t('PreviewParticipantConfirmation') }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item @click="signatureListDialogControl(false)">
                            <v-list-item-icon>
                              <v-icon right>{{ 'mdi-signature-freehand' }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>{{ $t('GenerateSignatureList') }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item v-if="!isInhouse" @click="generateParticipantList()">
                            <v-list-item-icon>
                              <v-icon right>{{ 'mdi-clipboard-list-outline' }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>{{ $t('GenerateParticipantList') }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item @click="generateParticipantListCsv()">
                            <v-list-item-icon>
                              <v-icon right>{{ 'mdi-file-delimited-outline' }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>{{ $t('GenerateParticipantListCsv') }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item @click="generateParticipantNameCardListCsv()">
                            <v-list-item-icon>
                              <v-icon right>{{ 'mdi-file-delimited-outline' }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>{{ $t('GenerateParticipantNameCardListCsv') }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item @click="generateParticipantEmailListCsv()">
                            <v-list-item-icon>
                              <v-icon right>{{ 'mdi-file-delimited-outline' }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>{{ $t('GenerateParticipantEmailListCsv') }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item
                            v-if="isInhouse || seminar.noOnline"
                            :disabled="!seminar.enabled"
                            @click="participantConfirmationsPackage(seminarModule)"
                          >
                            <v-list-item-icon>
                              <v-icon right>{{ 'mdi-package-down' }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title
                                >{{ $t('DownloadAllParticipantConfirmationsPackage') }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <SignatureListDialog
                        v-model="showSignatureListDialogForm"
                        @close="signatureListDialogControl(true)"
                        :show-handle="showSignatureListDialogForm"
                        :seminar-module-item="seminarModule"
                      />
                    </template>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row v-if="hasRole('ROLE_UPDATE_SEMINAR_MODULE')">
            <v-col cols="12">
              <v-card outlined tile>
                <v-card-title>Aktionen</v-card-title>
                <v-card-text v-if="!seminarModule.cancelled">
                  <div v-if="!seminarModule.enabled" class="mb-2">
                    <v-btn color="success" class="mr-2" @click="confirmSetEnable">
                      {{ $t('SetEnable') }}
                    </v-btn>
                  </div>
                  <div
                    v-if="seminarModule.enabled && !seminarModule.cancelled && !seminarModule.confirmed"
                    class="mb-2"
                  >
                    <v-btn color="error" class="mr-2" @click="confirmSetDisable">
                      {{ $t('SetDisable') }}
                    </v-btn>
                  </div>

                  <div
                    v-if="seminarModule.enabled && !seminarModule.cancelled && !seminarModule.confirmed"
                    class="mb-2"
                  >
                    <v-btn :disabled="!seminarModule.enabled" color="success" class="mr-2" @click="confirmSetConfirmed">
                      {{ $t('SetConfirmed') }}
                    </v-btn>
                    <v-btn :disabled="!seminarModule.enabled" color="warning" class="mr-2" @click="confirmSetCancelled">
                      {{ $t('SetCancelled') }}
                    </v-btn>
                  </div>

                  <div
                    v-if="seminar.noOnline === false && seminar.seminarHappens && !seminarModule.completed"
                    class="mb-2"
                  >
                    <v-btn
                      color="primary"
                      class="mr-2"
                      @click.prevent="confirmSendAllInvitations"
                      :disabled="!meeting && !seminarModule.externalMeeting && seminar.noOnline === false"
                    >
                      {{ $t('InviteAllParticipants') }}
                      <v-icon right>{{ 'mdi-account-multiple-plus' }}</v-icon>
                    </v-btn>

                    <v-btn
                      color="primary"
                      class="mr-2"
                      @click.prevent="confirmSendSpeakerInvitations"
                      :disabled="!meeting && !seminarModule.externalMeeting && seminar.noOnline === false"
                    >
                      {{ $t('InviteAllSpeakers') }}
                      <v-icon right>{{ 'mdi-account-multiple-plus' }}</v-icon>
                    </v-btn>

                    <span
                      v-if="!meeting && !seminarModule.externalMeeting && seminar.noOnline === false"
                      class="ml-2 font-weight-bold"
                    >
                      Kein BBB Meeting verfügbar.
                      <v-btn
                        color="primary"
                        style="height: auto"
                        class="pa-0 text-none"
                        plain
                        tile
                        @click.prevent="$vuetify.goTo('#create-meeting')"
                        >Hier verwalten</v-btn
                      >
                    </span>
                  </div>

                  <div class="mb-2">
                    <v-btn
                      v-if="!seminarModule.completed && seminar.seminarHappens"
                      color="success"
                      class="mr-2"
                      @click="confirmSetCompleted"
                    >
                      {{ $t('SetCompleted') }}
                    </v-btn>
                    <v-btn
                      v-if="isPublicBookable && this.seminarModule.autoInvoice"
                      color="success"
                      @click="confirmSendInvoices"
                      class="mr-2"
                    >
                      {{ $t('SendInvoices') }}
                    </v-btn>
                  </div>
                  <div v-if="seminarModule.completed && seminar.seminarHappens" class="mb-2">
                    <v-btn
                      color="primary"
                      class="mr-2"
                      @click="confirmSendSpeakerCertificates"
                      :disabled="seminarModule.speakerCertificationSent"
                    >
                      {{ $t('SendSpeakerCertificates') }}
                    </v-btn>
                    <v-btn
                      :disabled="isNoOnlineSeminar || seminarModule.participantCertificationsSent"
                      color="primary"
                      class="mr-2"
                      @click="confirmSendParticipantConfirmations"
                    >
                      {{ $t('SendParticipantConfirmations') }}
                    </v-btn>
                    <v-btn
                      color="primary"
                      class="mr-2"
                      @click="confirmGenerateInvoiceParticipant"
                      v-if="seminar.billingPerParticipant && !seminar.invoice && this.isInhouse && seminar.offer"
                    >
                      {{ $t("generateInvoiceForNumberOfParticipants") }}
                    </v-btn>
                  </div>
                  <div v-if="!seminarModule.autoInvoice && seminarModule.confirmed && !seminar.billingPerParticipant" class="mb-2">
                    <v-btn color="primary" class="mr-2" @click="invoiceApprovalDialogControl(false)">
                      {{ $t('InvoiceApproval') }}
                    </v-btn>
                    <InvoiceApprovalDialog
                      v-model="showInvoiceApprovalDialog"
                      @close="invoiceApprovalDialogControl(true)"
                      :title="$t('Rechnungsversand')"
                      :show-handle="showInvoiceApprovalDialog"
                      :seminarModule="this.seminarModule"
                    />
                  </div>
                </v-card-text>
                <v-card-text v-else>
                  <strong>Seminar wurde storniert</strong>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-card outlined tile>
                <v-card-text>
                  <ParticipantListAndAdd
                    ref="participantListAndAdd"
                    :default-item-props="{ seminarModule: seminarModule['@id'] }"
                    :seminarHappens="seminar.seminarHappens"
                    :is-public-bookable="isPublicBookable"
                    :seminarModule="seminarModule"
                    :participants="seminarModule.participants"
                    :is-no-online="isNoOnlineSeminar"
                    :locked="!seminar.enabled"
                    object-type="seminarModule"
                    @updateParticipants="updateParticipantsHandler"
                  />
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12">
              <template v-if="seminar.noOnline === false">
                <template v-if="seminarModule.completed === false">
                  <v-card outlined tile class="fill-height" id="create-meeting">
                    <v-overlay :absolute="true" :value="seminarModule.confirmed === false || seminarModule.cancelled" />
                    <v-card-title>{{ $t('OnlineMeetingRoom') }}</v-card-title>
                    <v-card-text v-if="meeting && !seminar.externalMeeting">
                      <p v-if="seminarModule.confirmed === false || seminarModule.cancelled">
                        Das Seminar muss
                        <template v-if="!seminarModule.enabled"> aktiviert und </template>
                        bestätigt sein, damit der Seminar-Raum in Big Blue Button verwaltet werden kann.
                      </p>
                      <v-row>
                        <v-col cols="12" md="12">
                          <h4>Meeting Status:</h4>
                          <span v-if="meeting.running" style="color: green">
                            Raum ist aktiv.
                            {{ meeting.moderatorCount }} Moderator(en) &amp; {{ meeting.participantCount }} Teilnehmer
                          </span>
                          <span v-if="!meeting.running" style="color: red">Raum ist nicht aktiv.</span>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon @click="refreshMeetingRoom" v-bind="attrs" v-on="on">
                                <v-icon>{{ 'mdi-refresh' }}</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t('RefreshRoomStatus') }}</span>
                          </v-tooltip>
                          <v-btn
                            color="primary"
                            small
                            outlined
                            :disabled="!seminar.seminarHappens || meeting.running"
                            v-if="!meeting.running"
                            @click="startMeeting()"
                          >
                            {{ $t('StartMeeting') }}
                            <v-icon right>{{ 'mdi-play-circle-outline' }}</v-icon>
                          </v-btn>
                        </v-col>
                        <v-col cols="12" md="12">
                          <v-btn
                            color="primary"
                            small
                            outlined
                            @click="meetingModeratorDialogControl()"
                            :disabled="!seminar.seminarHappens || !meeting.running"
                          >
                            {{ $t('JoinAsModerator') }}
                            <v-icon right>{{ 'mdi-account-plus' }}</v-icon>
                          </v-btn>
                          <ModeratorDialog
                            v-model="showMeetingModeratorDialog"
                            :show-handle="showMeetingModeratorDialog"
                            :seminar-module-id="seminarModule['@id']"
                            :meeting="meeting"
                            @close="showMeetingModeratorDialog = false"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-text v-else>
                      <template
                        v-if="
                          seminarModule.enabled &&
                          !seminarModule.cancelled &&
                          !seminarModule.meeting &&
                          seminarModule.confirmed &&
                          !seminarModule.externalMeeting
                        "
                      >
                        <v-btn color="success" class="mr-2" @click="blankUpdate">
                          {{ $t('CreateMeeting') }}
                        </v-btn>
                      </template>
                      <template v-else>
                        <template v-if="seminar.externalMeeting">
                          <template v-if="seminarModule.enabled && !seminarModule.cancelled && seminarModule.confirmed">
                            Das Seminar findet über einen externen Online Seminar-Raum statt. <br />
                            <strong>URL</strong>:
                            <a :href="seminarModule.externalMeeting.joinUrl">{{
                              seminarModule.externalMeeting.joinUrl
                            }}</a
                            ><br />
                            <strong>Notiz</strong>: {{ seminarModule.externalMeeting.title }}<br />
                            <strong>Passwort</strong>: {{ seminarModule.externalMeeting.password }}<br />
                          </template>
                          <template v-else>
                            <p v-if="seminarModule.confirmed === false || seminarModule.cancelled">
                              Das Seminar muss
                              <template v-if="!seminarModule.enabled"> aktiviert und </template>
                              bestätigt sein, damit die externen Meeting-Infos angezeigt werden können.
                            </p>
                          </template>
                        </template>
                        <template v-else>
                          Das Seminar muss
                          <template v-if="!seminarModule.enabled"> aktiviert und </template>
                          bestätigt sein, damit der Seminar-Raum in Big Blue Button verwaltet werden kann.
                        </template>
                      </template>
                    </v-card-text>
                  </v-card>
                </template>
              </template>
              <template v-else-if="seminar.presenceSeminar === true">
                <v-card outlined tile class="fill-height">
                  <v-card-title>{{ $t('Venue') }}</v-card-title>
                  <template v-if="venue">
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col>
                            <h3>{{ $t('Name') }}:</h3>
                            {{ venue.name }}
                            <h3>{{ $t('contactPerson') }}:</h3>
                            {{ venue.contactPerson }}
                          </v-col>
                          <v-col>
                            <h3>{{ $t('Address') }}:</h3>
                            <template v-if="venue.address.additional">{{ venue.address.additional }}<br /></template>
                            {{ venue.address.streetNr }}<br />
                            {{ venue.address.zipCode }} {{ venue.address.city }}<br />
                            {{ venue.address.country }}
                          </v-col>
                          <v-col>
                            <h3>{{ $t('Fees') }}:</h3>
                            <b>{{ $t('hotelRate') }}</b
                            >: {{ venue.rate }} €<br />
                            <b>{{ $t('flatRate') }}</b
                            >: {{ venue.flatRate }} €<br />
                          </v-col>
                        </v-row>
                        <template v-if="false">
                          <v-row>
                            <v-col>
                              <h3>{{ $t('venueImagePlaceHolder') }}:</h3>
                              <v-img
                                v-if="venue.image.contentUrl"
                                :src="documentEntryPoint + venue.image.contentUrl"
                                max-height="450"
                                max-width="550"
                              ></v-img>
                            </v-col>
                          </v-row>
                        </template>
                      </v-container>
                    </v-card-text>
                  </template>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text elevation="2" color="primary" @click="showVenue(venue)">
                      {{ $t('showVenueDetails') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-col>
          </v-row>
          <template v-if="hasRole('ROLE_READ_EXPENSE')">
            <v-divider class="mt-8" />
            <v-card flat class="mt-4">
              <v-card-title>{{ $t('Budget') }}</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" v-if="seminar.offer" class="pa-0">
                    <v-card flat>
                      <v-card-title style="justify-content: center" class="pa-0">
                        Verknüpftes Angebot: {{ seminar.offer.number }}
                      </v-card-title>
                    </v-card>
                  </v-col>
                  <v-col cols="4" offset="2">
                    <v-card flat>
                      <v-card-title style="justify-content: center">
                        Gewinn:
                        <v-chip :color="profitColor" class="ml-2">{{ profit }} &euro;</v-chip>
                      </v-card-title>
                    </v-card>
                  </v-col>
                  <v-col cols="4">
                    <v-card flat>
                      <v-card-title style="justify-content: center">
                        Gesamtausgaben: {{ seminar.totalExpenses }}€
                      </v-card-title>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-row>
              <v-col cols="12">
                <v-card class="pa-1 fill-height" outlined tile>
                  <v-card-title>{{ $t('SpeakerFeeTitle') }}</v-card-title>
                  <v-card-text>
                    <div v-for="speakerFee in seminar.speakerFees" :key="speakerFee['speaker']">
                      <strong>{{ speakerFee.name }}</strong
                      >: {{ speakerFee.rate }}&euro;
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12">
                <ExpenseListAdd
                  :default-item-props="{ seminar: seminar['@id'] }"
                  :expenses="expensesCombined"
                  object-type="seminar"
                  :show-message="showMessage"
                  :locked="false"
                  @updateExpenses="updateExpensesHandler"
                />
              </v-col>
            </v-row>
          </template>

          <v-row>
            <v-col cols="6" v-if="hasRole('ROLE_READ_NOTE')">
              <NoteListAndAdd
                :default-item-props="{ seminar: this.item['@id'] }"
                :notes="this.item.notes"
                object-type="seminar"
                :show-message="showMessage"
                @updateNotes="updateNotesHandler"
              />
            </v-col>
            <v-col cols="6">
              <DocumentListAndAdd :documents="seminarModule.files" @uploaded="onUploadedFiles" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <SeminarCombinedDialog
        v-model="showSeminarDialogForm"
        :seminar-item="seminar"
        :seminar-module-item="seminarModule"
        :handle-updated="handleSeminarUpdated"
        :show-handle="showSeminarDialogForm"
        :allow-name="isInhouse"
        :title="seminarDialogTitle"
        @close="seminarDialogControl(true)"
        v-if="!isLoading"
      />
    </v-card>
    <Loading :visible="isLoading" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import Loading from '../../components/Loading';
import ShowMixin from '../../mixins/ShowMixin';
import Toolbar from '../../components/Toolbar';
import SpeakerMixin from '@/mixins/SpeakerMixin';
import TenantMixin from '@/mixins/TenantMixin';
import ExpenseListAdd from '../../components/expense/ListAndAdd';
import NoteListAndAdd from '../../components/note/ListAndAdd';
import remove from 'lodash/remove';
import ExpenseMixin from '@/mixins/ExpenseMixin';
import TopicMixin from '@/mixins/TopicMixin';
import SeminarModuleMixin from '@/mixins/SeminarModuleMixin';
import SeminarCombinedDialog from '@/components/seminarCombined/Dialog';
import SeminarMixin from '@/mixins/SeminarMixin';
import TimeSlotMixin from '@/mixins/TimeSlotMixin';
import StatusChip from '@/components/generic/StatusChip';
import ProfileMixin from '@/mixins/ProfileMixin';
import ContentHtmlDialog from '@/components/generic/ContentHtmlDialog';
import ParticipantListAndAdd from '@/components/participant/ListAndAdd';
import DocumentListAndAdd from '@/components/document/ListAndAdd';
import MeetingMixin from '@/mixins/MeetingMixin';
import ModeratorDialog from '@/components/moderator/Dialog';
import DocumentMixin from '@/mixins/DocumentMixin';
import VenueMixin from '@/mixins/VenueMixin';
import SignatureListDialog from '@/components/seminarModule/SignatureListDialog';
import InvoiceApprovalDialog from '../../components/seminar/InvoiceApprovalDialog.vue';

const servicePrefix = 'Seminar';

export default {
  name: 'SeminarShow',
  servicePrefix,
  components: {
    ContentHtmlDialog,
    StatusChip,
    SeminarCombinedDialog,
    Loading,
    Toolbar,
    ExpenseListAdd,
    NoteListAndAdd,
    ParticipantListAndAdd,
    ModeratorDialog,
    DocumentListAndAdd,
    SignatureListDialog,
    InvoiceApprovalDialog
  },
  mixins: [
    ShowMixin,
    SeminarMixin,
    SpeakerMixin,
    ProfileMixin,
    TenantMixin,
    ExpenseMixin,
    TopicMixin,
    SeminarModuleMixin,
    TimeSlotMixin,
    MeetingMixin,
    DocumentMixin,
    VenueMixin
  ],
  data() {
    return {
      tab: true,
      moduleFormItem: {},
      noteFormItem: {},
      showModuleDialogForm: false,
      showNoteDialogForm: false,
      loadingNotes: [],
      speakerFeeHeaders: [
        { text: this.$t('Name'), value: 'name' },
        { text: this.$t('SeminarModule'), value: 'seminarModuleName' },
        { text: this.$t('fee'), value: 'rate' },
        { text: this.$t('isCoSpeaker'), value: 'isCoSpeaker' }
      ],
      modulesFilter: {
        options: {
          sortBy: [],
          descending: false,
          page: 1,
          itemsPerPage: 15
        },
        filters: {}
      },
      notesFilter: {
        options: {
          sortBy: [],
          descending: false,
          page: 1,
          itemsPerPage: 15
        },
        filters: {}
      },
      loadingFlags: {
        notes: false
      },
      showSeminarDialogForm: false,
      showSignatureListDialogForm: false,
      seminarDialogTitle: this.$t('EditSeminar'),
      oldSeminar: null,

      seminarContentDialog: false,
      showMeetingModeratorDialog: false,
      confirmSetDisableMessage: this.$t("confirmSetDisableMessage"),
      confirmSetEnableMessage: this.$t("confirmSetEnableMessage"),
      confirmSetCompletedMessage: this.$t("confirmSetCompletedMessage"),
      confirmSetConfirmedMessage: this.$t("confirmSetConfirmedMessage"),
      confirmSetCancelledMessage: this.$t("confirmSetCancelledMessage"),
      confirmSendAllInvitationsMessage: this.$t("confirmSendAllInvitationsMessage"),
      confirmSendSpeakerInvitationsMessage: this.$t("confirmSendSpeakerInvitationsMessage"),
      confirmBlankUpdateMessage: this.$t("confirmBlankUpdateMessage"),
      confirmDeactivateAutoInvoice: this.$t("confirmDeactivateAutoInvoice"),
      confirmSendParticipantConfirmationsMessage: this.$t(
          "confirmSendParticipantConfirmationsMessage",
      ),
      confirmSendSpeakerCertificatesMessage: this.$t(
          "confirmSendSpeakerCertificatesMessage",
      ),
      confirmSendParticipantsInvoiceMessage: this.$t(
          "confirmSendParticipantsInvoiceMessage",
      ),
      confirmPrepareInvoiceForNumberOfParticipantsMessage: this.$t(
        "confirmPrepareInvoiceForNumberOfParticipantsMessage"
      ),
      showInvoiceApprovalDialog: false,
      isForceLoading: false
    };
  },
  computed: {
    ...mapFields('seminar', {
      isSeminarLoading: 'isLoading',
      seminarUpdated: 'updated',
      seminarHandled: 'handled',
      seminarActionHandled: 'handleAction'
    }),
    ...mapFields('seminarModule', {
      isModuleLoading: 'isLoading',
      seminarModuleHandled: 'handled',
      seminarModuleActionHandled: 'handleAction'
    }),
    ...mapGetters('seminar', { find: 'find' }),
    profit() {
      return Math.round((this.item.totalEarnings - this.item.totalExpenses) * 100) / 100;
    },
    profitColor() {
      return this.profit > 0 ? 'green' : 'red';
    },
    topics() {
      return this.resolveTopics(this.item.topics);
    },
    isFaoEnabled() {
      return this.item.faoTrainingEnabled ?? false;
    },
    isNoOnlineSeminar() {
      return this.item.noOnline ?? false;
    },
    isDeleteEnabled() {
      return this.hasRole('ROLE_DELETE_SEMINAR') && !this.seminarModule?.completed;
    },
    isEditEnabled() {
      return this.hasRole('ROLE_UPDATE_SEMINAR');
    },

    isLoading() {
      return this.isSeminarLoading || this.isModuleLoading || this.isForceLoading;
    },
    seminarModule() {
      if (typeof this.item === 'undefined' || this.item === null || Array.isArray(this.item?.modules) === false) {
        return null;
      }
      return this.resolveSeminarModule(this.item.modules[0]);
    },
    seminar() {
      return this.item;
    },
    venue() {
      if (typeof this.item === 'undefined' || this.item === null || this.item.venue === null) {
        return null;
      }
      return this.resolveVenue(this.item.venue);
    },
    timeSlot() {
      if (this.seminarModule) {
        return this.resolveTimeSlot(this.seminarModule.timeSlot);
      }
      return null;
    },
    speaker() {
      if (this.seminarModule) {
        return this.speakerById(this.seminarModule.speaker);
      }
      return null;
    },
    coSpeaker() {
      if (this.seminarModule) {
        return this.speakerById(this.seminarModule.coSpeaker);
      }
      return null;
    },
    speakerProfile() {
      if (this.speaker) {
        return this.resolveProfile(this.speaker.profile);
      }
      return null;
    },
    coSpeakerProfile() {
      if (this.coSpeaker) {
        return this.resolveProfile(this.coSpeaker.profile);
      }
      return null;
    },
    isPublicBookable() {
      return this.currentSeminarSettings['publicBookable'];
    },
    meeting() {
      if (this.seminarModule) {
        return this.seminarModule.meeting ? this.resolveMeeting(this.seminarModule.meeting) : null;
      }
      return null;
    },
    expensesCombined() {
      if (this.seminar && this.seminarModule) {
        let expensesSeminar = this.seminar.expenses ?? [];
        let expensesModule = this.seminarModule.expenses ?? [];

        return expensesSeminar.concat(expensesModule);
      }
      return null;
    }
  },
  methods: {
    log(data) {
      console.log(data);
    },
    ...mapActions('seminar', {
      deleteItem: 'del',
      reset: 'reset',
      retrieve: 'load',
      updateSeminar: 'update',
      actionSeminar: 'action'
    }),
    ...mapActions('seminarModule', {
      retrieveModule: 'load',
      deleteModuleItem: 'del',
      updateModule: 'update',
      actionModule: 'action'
    }),
    reloadSeminar() {
      // re-fetch module
      return this.retrieve(decodeURIComponent(this.item['@id']));
    },
    moduleDialogControl(close = false) {
      this.showModuleDialogForm = !close;
    },
    createOrEditModuleHandler(moduleItem) {
      this.moduleFormItem = moduleItem ?? {
        seminar: this.item['@id'],
        timeSlot: { start: null, end: null },
        files: []
      };
      this.moduleDialogControl();
    },
    seminarDialogControl(close = false) {
      this.oldSeminar = this.item;
      this.showSeminarDialogForm = !close;
    },
    signatureListDialogControl(close = false) {
      this.showSignatureListDialogForm = !close;
    },
    deleteModuleHandler(item) {
      this.item.modules = remove(this.item.modules, function (n) {
        return !(n === item['@id']);
      });
      this.deleteModuleItem(item);
    },
    inviteAllParticipants(item) {
      this.actionSeminar({ item, action: 'invite' });
    },
    inviteSpeakers() {
      this.meetingActions({
        item: this.meeting ? this.meeting : this.seminarModule.externalMeeting,
        action: 'invite-speakers',
        params: [{ key: 'force', value: 1 }]
      });
    },
    updateNotesHandler(updatedNotes) {
      this.item.notes = updatedNotes;
    },
    updateExpensesHandler(updatedExpenses) {
      this.item.expenses = updatedExpenses;
      this.reloadSeminar();
    },
    copy() {
      this.actionSeminar({ item: this.item, action: 'copy' });
    },
    generateParticipantList() {
      this.downloadDocument(`${this.seminarModule['@id']}/generateParticipantList`, 'Teilnehmerliste.pdf', true);
    },
    generateParticipantListCsv() {
      this.downloadDocument(`${this.seminarModule['@id']}/generateParticipantListCsv`, 'Teilnehmerliste.csv', true);
    },
    generateParticipantNameCardListCsv() {
      this.downloadDocument(
        `${this.seminarModule['@id']}/generateParticipantNameCardListCsv`,
        'Namenskarten.csv',
        true
      );
    },
    generateParticipantEmailListCsv() {
      this.downloadDocument(`${this.seminarModule['@id']}/generateParticipantEmailListCsv`, 'Emailliste.csv', true);
    },
    async handleSeminarUpdated(updatedItem) {
      await this.reloadSeminar();
      await this.refreshSeminarModules(updatedItem.modules);
      this.seminarDialogControl(true);
    },
    updateParticipantsHandler(updatedParticipants) {
      this.seminarModule.participants = updatedParticipants;
      this.reloadSeminar();
    },
    startMeeting() {
      this.meetingActions({ item: this.meeting, action: 'start' });
    },
    refreshMeetingRoom() {
      this.retrieveMeeting(decodeURIComponent(this.meeting['@id']));
    },
    meetingModeratorDialogControl(close = false) {
      this.showMeetingModeratorDialog = !close;
    },
    previewParticipantConfirmation(item) {
      this.downloadDocument(
        `/participants/confirmation/preview/${item['@id'].replace('/seminar_modules/', '')}`,
        'preview.pdf',
        true
      );
    },

    confirmSetCompleted() {
      this.$confirm(this.confirmSetCompletedMessage, {
        buttonTrueText: this.$t('Yes'),
        buttonFalseText: this.$t('No')
      }).then(async (response) => {
        if (!response) {
          return;
        }
        this.isForceLoading = true;
        await this.updateModule({
          ['@id']: this.seminarModule['@id'],
          completed: true
        });
        await this.reloadSeminarData();
        this.isForceLoading = false;
        this.showMessage('Seminar abgeschlossen.');
      });
    },
    confirmSendAllInvitations() {
      this.$confirm(this.confirmSendAllInvitationsMessage, {
        buttonTrueText: this.$t('Yes'),
        buttonFalseText: this.$t('No')
      }).then((response) => {
        if (!response) {
          return;
        }
        this.inviteAllParticipants(this.seminar);
      });
    },
    confirmSendSpeakerInvitations() {
      this.$confirm(this.confirmSendSpeakerInvitationsMessage, {
        buttonTrueText: this.$t('Yes'),
        buttonFalseText: this.$t('No')
      }).then((response) => {
        if (!response) {
          return;
        }
        this.inviteSpeakers();
      });
    },
    confirmSetEnable() {
      this.$confirm(this.confirmSetEnableMessage, {
        buttonTrueText: this.$t('Yes'),
        buttonFalseText: this.$t('No')
      }).then((response) => {
        if (!response) {
          return;
        }
        this.updateSeminar({
          ['@id']: this.seminar['@id'],
          enabled: true
        });
        this.updateModule({
          ['@id']: this.seminarModule['@id'],
          enabled: true
        });
      });
    },
    confirmSetDisable() {
      this.$confirm(this.confirmSetDisableMessage, {
        buttonTrueText: this.$t('Yes'),
        buttonFalseText: this.$t('No')
      }).then((response) => {
        if (!response) {
          return;
        }
        this.updateSeminar({
          ['@id']: this.seminar['@id'],
          enabled: false
        });
        this.updateModule({
          ['@id']: this.seminarModule['@id'],
          enabled: false
        });
      });
    },
    confirmSetConfirmed() {
      this.$confirm(this.confirmSetConfirmedMessage, {
        buttonTrueText: this.$t('Yes'),
        buttonFalseText: this.$t('No')
      }).then(async (response) => {
        if (!response) {
          return;
        }
        await this.updateModule({
          ['@id']: this.seminarModule['@id'],
          confirmed: true
        });
        await this.reloadSeminarData();
        this.showMessage('Seminar Bestätigt.');
      });
    },
    confirmSetCancelled() {
      this.$confirm(this.confirmSetCancelledMessage, {
        buttonTrueText: this.$t('Yes'),
        buttonFalseText: this.$t('No')
      }).then(async (response) => {
        if (!response) {
          return;
        }
        await this.updateModule({
          ['@id']: this.seminarModule['@id'],
          cancelled: true
        });
        this.showMessage('Seminar Storniert.');
      });
    },
    confirmSendParticipantConfirmations() {
      this.$confirm(this.confirmSendParticipantConfirmationsMessage, {
        buttonTrueText: this.$t('Yes'),
        buttonFalseText: this.$t('No')
      }).then(async (response) => {
        if (!response) {
          return;
        }
        await this.actionModule({
          item: this.seminarModule,
          action: 'participant_confirmation_send'
        });
        this.isForceLoading = true;
        await this.reloadSeminarData();
        this.isForceLoading = false;
        this.showMessage('Teilnahmebestätigungen versendet.');
      });
    },
    confirmSendSpeakerCertificates() {
      this.$confirm(this.confirmSendSpeakerCertificatesMessage, {
        buttonTrueText: this.$t('Yes'),
        buttonFalseText: this.$t('No')
      }).then(async (response) => {
        if (!response) {
          return;
        }
        await this.actionModule({
          item: this.seminarModule,
          action: 'speaker_certificates_send'
        });
        this.showMessage('Referentenbescheinigung versendet.');
      });
    },
    confirmGenerateInvoiceParticipant() {
      this.$confirm(this.confirmPrepareInvoiceForNumberOfParticipantsMessage, {
        buttonTrueText: this.$t("Yes"),
        buttonFalseText: this.$t("No"),
      }).then(async (response) => {
        if (!response) {
          return;
        }
        await this.actionSeminar({
          item: this.seminar,
          action: "prepare_invoice_per_participant",
        });
      });
    },
    confirmSendInvoices() {
      this.$confirm(this.confirmSendParticipantsInvoiceMessage, {
        buttonTrueText: this.$t('Yes'),
        buttonFalseText: this.$t('No')
      }).then(async (response) => {
        if (!response) {
          return;
        }
        await this.actionModule({
          item: this.seminarModule,
          action: 'generateAndSendParticipantsInvoice',
          dtoResponse: true
        });
        this.showMessage('Rechnungen versendet.');
      });
    },
    blankUpdate() {
      this.$confirm(this.confirmBlankUpdateMessage, {
        buttonTrueText: this.$t('Yes'),
        buttonFalseText: this.$t('No')
      }).then((response) => {
        if (!response) {
          return;
        }
        this.updateModule({
          ['@id']: this.seminarModule['@id']
        });
      });
    },
    onUploadedFiles() {
      this.updateModule({
        ['@id']: this.seminarModule['@id'],
        files: this.seminarModule.files
      });
      this.showMessage(this.$t('FileAdded'));
    },
    showVenue(item) {
      this.$router.push({
        name: 'VenueUpdate',
        params: { id: item['@id'] }
      });
    },
    participantConfirmationsPackage() {
      this.downloadDocument(
        `${this.seminarModule['@id']}/generate_participants_confirmation_package`,
        `pcp_${this.seminar.number}.zip`,
        true
      );
    },
    invoiceApprovalDialogControl(close = false) {
      this.showInvoiceApprovalDialog = !close;
    },
    deactivateAutoInvoice() {
      this.$confirm(this.confirmDeactivateAutoInvoice, {
        buttonTrueText: this.$t('Yes'),
        buttonFalseText: this.$t('No')
      }).then((response) => {
        if (!response) {
          return;
        }
        this.updateModule({
          ['@id']: this.seminarModule['@id'],
          autoInvoice: false
        });
      });
    },
    async reloadSeminarData() {
      await this.reloadSeminar();
      await this.$refs.participantListAndAdd.refreshParticipantData();
    }
  },
  watch: {
    seminarHandled() {
      if (this.seminarActionHandled === 'copy') {
        this.showMessage(this.$t('SeminarCopied'));
        this.$router.push({
          name: 'SeminarShow',
          params: { id: this.seminarHandled['@id'] }
        });
        this.seminarActionHandled = null;
        this.seminarHandled = null;
      }

      if (this.seminarActionHandled === "prepare_invoice_per_participant") {
        // redirect to invoice edit page
        this.$router.push({
          name: `InvoiceUpdate`,
          params: {id: this.seminarHandled["@id"]}
        });
      }
    },
    seminarModuleHandled() {
      if (this.seminarModuleActionHandled === 'generateAndSendParticipantsInvoice') {
        this.showMessage(this.$t('InvoicesSentToParticipants'));
        this.seminarModuleActionHandled = null;
        this.seminarModuleHandled = null;
      }
    },
    item(current, before) {
      if (typeof before === 'undefined' || before === null) {
        this.refreshSeminarModules(current.modules);
      }
    }
  },
  created() {
    if (typeof this.item !== 'undefined' && this.item !== null) {
      this.refreshSeminarModules(this.item.modules);
    }
  }
};
</script>
